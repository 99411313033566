<template>
  <div :class="{ 'row': !hideBudgetChanges, 'm-0': inNotifications }" class="account-view-budget">
    <!-- APPROVED_BUDGET -->
    <div :class="{ 'col-6': !hideBudgetChanges, 'p-0': inNotifications }">
      <div class="rounded-left b-left pt-2" :class="{'active-budget-editing border border-bottom-0': hasBudgetChanges}">
        <div v-if="!campaignGroupBudgets">
          <div :class="{ 'row no-gutters': !value }">
            <!-- begin grouped account budget settings -->
            <template v-if="sharedApprovedAccountBudget"> <!-- check capability for shared account budgets -->
              <div :class="{ 'col-6': !value }">
                <label class="cursor-pointer d-flex">
                  <span>Group Approved Account Budget</span>
                </label>
                <div>
                  {{$filters.currency(account.sharedApprovedAccountBudget)}}
                  <info-tooltip popover
                                title="The approved budget for all accounts in the group."
                                class="mx-3" />
                </div>
              </div>
              <b-form-group v-if="!hideBudgetChanges" class="mb-0 pb-5">
                <div class="pt-2 mt-1">
                  <b-form-checkbox :checked="account.sharedAccountOverspendProtection"
                                   @input="account.sharedAccountOverspendProtection = $event"
                                   class="d-inline-block"
                                   @change="updateAccount({sharedAccountOverspendProtection: $event})">
                    Enable Group Overspend Protection
                  </b-form-checkbox>
                  <field-activity v-if="!value" field="AccountPlan.sharedAccountOverspendProtection"/>
                </div>
                <div class="pt-2 mt-2">
                  <p class="small text-muted my-2">Places accounts in the group on hold when the approved account budget amount is exhausted.</p>
                </div>
              </b-form-group>
            </template>
            <!-- end grouped account budget settings -->
            <div :class="{ 'col-6': !value }">
              <label class="cursor-pointer d-flex" @click="toggleEditingMode()">
                <span>{{approvedLabel}}</span>
                <fluency-icon type="edit" class="mx-3" />
                <field-activity v-if="!value"
                                field="AccountPlan.approvedAccountBudget" />
              </label>
              <div class="mad-lib-container">
                <basic-mad-lib-input :value="account.approvedAccountBudget"
                                     format="CURRENCY"
                                     :disabled="disabled"
                                     @keydown.enter.stop.prevent
                                     @focus="previewBudgetChange(account.approvedAccountBudget, { focus: true })"
                                     @input="previewBudgetChange($event, { emit: true })"></basic-mad-lib-input>
                <info-tooltip popover
                              title="Changing this value will adjust the budgets below based on current allotment ratios"
                              class="mx-3" />
                <fluency-icon v-show="previewLoading" class="ml-2 rotating" type="loop" />
              </div>
            </div>
            <div v-if="account.accountBudgetAdjustment" class="text-right">
              <t-row class="pl-2 mr-1">
                <div class="col p-4 text-left">Adjustment:</div>
                <div class="col p-4 text-right">{{(account.accountBudgetAdjustment > 0)?'+':''}}{{$filters.number(account.accountBudgetAdjustment, 2)}}</div>
              </t-row>
              <t-row class="pl-2 mr-1">
                <div class="col px-4 text-left">Target Amount:</div>
                <div class="col px-4 text-right">{{$filters.currency(account.accountBudgetAdjustment + account.approvedAccountBudget, 2)}}</div>
              </t-row>
            </div>
          </div>
        </div>

        <b-form-group v-if="!hideBudgetChanges" class="mb-0 pb-5">
          <div class="pt-2 mt-1">
            <b-form-checkbox :checked="account.applyOverspendProtection"
                             @input="account.applyOverspendProtection = $event"
                             class="d-inline-block"
                             @change="updateAccount({applyOverspendProtection: $event})">
              Enable Overspend Protection
            </b-form-checkbox>
            <field-activity v-if="!value" field="AccountPlan.applyOverspendProtection"/>
          </div>
          <div class="pt-2 mt-2">
            <p class="small text-muted my-2">Places account on hold when the approved account budget amount is exhausted.</p>
          </div>
        </b-form-group>

        <div class="mt-2" v-if="account.conservativeBudgetIncrease > 0">
          <view-proposal :account-plan-id="account.accountPlanId"
                         style="margin-left:-0.5em;"
                         button-style="ghost-success"
                         :account-name="account.name"
                         :button-text="`View Budget Proposal`"/>
        </div>
      </div>
    </div>
    <!-- /APPROVED_BUDGET -->

    <!-- NEXT_MONTH_BUDGET -->
    <div v-if="!hideBudgetChanges && !inNotifications" class="col-6">

      <div class="pt-2">
        <label>
          Next Month Budget Change
          <field-activity v-if="!value" field="AccountPlan.nextApprovedAccountBudget"/>
        </label>
        <div class="mad-lib-container">
          <basic-mad-lib-input :value="account.nextApprovedAccountBudget"
                               format="CURRENCY"
                               :disabled="disabled"
                               allow-empty-strings
                               @keydown.enter.stop.prevent
                               @input="updateAccount({nextApprovedAccountBudget: $event})" />
          <t-button-inline v-if="!isNil(account.nextApprovedAccountBudget)" class="ml-3" severity="secondary" @click="clearPending()">
            <fluency-icon type="close" />
          </t-button-inline>
        </div>
      </div>
      <div class="pt-1">
        <p class="small text-muted my-2">The amount set here will take effect at the next month boundary (11:55pm in
          the account's timezone ({{ account.timeZone }}), on the last day of the month). If set to a value that does
          not equal all active applied budgets, auto-scaling will occur.</p>
      </div>
    </div>
    <!-- /NEXT_MONTH_BUDGET -->

    <div v-if="inNotifications && !previewLoading" class="col-6 py-0">
      <template v-if="!budgetChange.budgetAdjustments && (!originalBudgetChange.budgetAdjustments || originalBudgetChange.budgetAdjustments.length === 0)">
        <h4 class="mb-3 text-info">Oh No!</h4>
        <span class="text-black-50">There aren't any budget's that we can distribute extra funds to.</span>
      </template>
      <template v-else>
        <h4 class="mb-3 text-info">How Does This Look?</h4>
        <span class="text-black-50">We've gone ahead and evenly spread around the difference in budget. Feel free to make manual adjustments below and save when it looks good.</span>
      </template>
    </div>

    <!-- BUDGET_CHANGES -->
    <div v-if="!hideBudgetChanges && hasBudgetChanges" class="col-12 mb-4" :class="{'p-0': inNotifications}" style="margin-top: -1px;">
      <div class="rounded-right rounded-bottom b-right" :class="{'active-budget-editing border': hasBudgetChanges}">
        <div class="b-right-content">
          <template v-if="hasBudgetChanges">
            <label>Monthly Budget Redistribution</label>
            <div style="padding-right:1em;">
              <label id="approvedBudgetChangeMessage">
                Change monthly distribution amounts yourself, or accept the default.
                <info-tooltip v-if="budgetChange && budgetChange.message" :boundary="inNotifications ? 'window' : undefined">
                  <template #title> <span v-markdown="budgetChange.message" /></template>
                </info-tooltip>
              </label>

              <fluent-table
                :use-flex-classes="false"
                :items="tableItems"
                :fields="budgetChangeFields"
                :tableClass="tableClasses"
              >
                <template #addNewButton>
                  <b-button variant="link" @click="revertToOriginalAmounts()">
                    <fluency-icon type="history"/>
                    Keep Original Amounts
                  </b-button>
                  <b-btn variant="link" class="pr-0" @click="clearOverrides()">
                    <fluency-icon type="measurement"/>
                    Use Scaled Amounts
                  </b-btn>
                  <info-tooltip :title="`Use scaled budget amounts based on the $${account.approvedAccountBudget} you have entered.`" />
                </template>

                <template #cell(originalBudgetAmount)="row">
                  {{ $filters.currency(row.item.budgetAmount, 2) }}
                  <div v-if="row.item.budgetPlanAdjustment"
                       class="font-weight-bold"
                       :class="[(row.item.budgetPlanAdjustment > 0) ? 'text-success' : 'text-info']">
                    {{(row.item.budgetPlanAdjustment > 0) ? '+' : ''}}{{$filters.currency(row.item.budgetPlanAdjustment, 2)}}
                  </div>
                </template>

                <template #cell(updatedBudgetAmount)="row">
                  <div v-if="row.item.updatedBudgetAmount" class="d-inline-block">
                    <basic-mad-lib-input :value="row.item.updatedBudgetAmount"
                                         format="CURRENCY"
                                         @input="row.item.updatedBudgetAmount = $event; updateManualBudget($event, row)">
                      <template #edit="{value, input, blur}">
                        <budget-adjuster :value="value"
                                         @input="input($event), blur()" />
                      </template>
                    </basic-mad-lib-input>
                  </div>
                  <div v-else class="font-italic">Budget Locked</div>
                </template>

              </fluent-table>

              <div class="bg-white border rounded mb-3 p-2">
                <div class="text-right">
                  <div v-if="Math.abs(currentDiff) >= 1">
                    <info-tooltip>
                      <template #title>
                      Set the <b>"Approved Account Budget"</b> amount to the new <b>"Using"</b> value.
                      </template>
                    </info-tooltip>
                    <b-btn variant="warning"
                           class="text-white py-0 mb-2 font-weight-bold"
                           @click="syncUp()">
                      <fluency-icon type="loop" /> Sync Approved Account Budget
                    </b-btn>
                  </div>
                </div>
                <div class="d-flex-end">
                  <div class="flex-grow-1 text-right mr-3">
                    <div>Using:</div>
                    <div>Remaining:</div>
                  </div>
                  <div class="text-right">
                    <div><b>{{ $filters.currency(updatedBudgetAmountSum, 2) }}</b></div>
                    <div><b :class="{ 'text-success': currentDiff >= 1, 'text-danger': currentDiff <= -1 }">{{ $filters.currency(currentDiff, 2) }}</b></div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FieldActivity from './forms/fieldActivity'
import ViewProposal from './viewProposal'
import _cloneDeep from 'lodash.clonedeep'
import _isNil from 'lodash.isnil'
import { reactive } from 'vue'
import BasicMadLibInput from 'core-ui/components/inputs/basicMadLibInput'
import BudgetAdjuster from '@/components/common/budgetAdjuster'

export default {
  name: 'accountViewBudget',
  components: {
    BudgetAdjuster,
    BasicMadLibInput,
    ViewProposal,
    FieldActivity
  },
  props: {
    value: { // account
      type: Object,
      required: true
    },
    hideBudgetChanges: {
      type: Boolean,
      default: false
    },
    inAccountView: {
      type: Boolean,
      default: false
    },
    inNotifications: {
      type: Boolean,
      default: false
    },
    accountBudgets: {
      type: Array
    },
    disabled: {
      type: Boolean,
      default: false
    },
    approvedLabel: {
      type: String,
      default: 'Approved Account Budget'
    },
    forceOpenDefault: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      budgetChange: {},
      showMore: false,
      budgetChangeFields: [
        { key: 'name', useDefaultTemplate: false, defaultWidth: 'auto' },
        { key: 'originalBudgetAmount', label: 'Original Monthly Amount', tdClass: 'text-right py-4', thClass: 'text-right', defaultWidth: '160', formatter: (v, k, item) => item.budgetAmount, sortByFormatted: true },
        { key: 'updatedBudgetAmount', label: 'Updated Monthly Amount', tdClass: 'text-right', thClass: 'text-right', defaultWidth: '160' }
      ],
      htmlMessage: '',
      forceOpen: this.forceOpenDefault,
      previewLoading: false
    }
  },
  watch: {
    accountPlanId () {
      this.budgetChange = {}
      this.forceOpen = false
    },
    'account.dirty' (newVal, oldVal) {
      if (oldVal && !newVal) {
        this.budgetChange = {}
        this.forceOpen = this.forceOpenDefault
      }
    },
    campaignGroupBudgets: { // Boolean type
      handler (val) {
        // do not open if using grouped budgets
        if (val) {
          this.forceOpen = false
        }
      },
      immediate: true
    }
  },
  computed: {
    sharedApprovedAccountBudget () {
      return this.$store.getters.user?.capabilities?.GroupedApprovedAccountBudgets
    },
    currentDiff () {
      return this.account.approvedAccountBudget - this.updatedBudgetAmountSum
    },
    originalBudgetChange () {
      return {
        budgetAdjustments: this.accountBudgets?.filter(budget => budget.inactive === false && budget.campaignPlans?.length >= 1 && budget.budgetType !== 'LIFETIME').map(budget => reactive({
          name: budget.name,
          budgetAmount: budget.budgetAmount,
          updatedBudgetAmount: budget.budgetAmount,
          budgetPlanId: budget.budgetPlanId,
          accountPlanId: budget.accountPlanId,
          budgetPlanAdjustment: budget?.budgetPlanAdjustment?.amount
        })) ?? []
      }
    },
    campaignGroupBudgets () {
      return this.inAccountView &&
        this.account?.capabilities?.CampaignGroupBudgets === true &&
        !!(this.account?.accountPlanId) &&
        !this.account?.readOnlyMode
    },
    updatedBudgetAmountSum () {
      if (this.budgetChange?.budgetAdjustments) {
        return this.budgetChange.budgetAdjustments.sum(budget => budget.updatedBudgetAmount)
      } else {
        return this.originalBudgetChange.budgetAdjustments.sum(budget => budget.updatedBudgetAmount)
      }
    },
    account: {
      set (val) {
        this.$emit('input', val)
      },
      get () {
        return this.value
      }
    },
    hasBudgetChanges () {
      // in the case of bulk budget editing we hide the adjustments using a prop
      if (this.hideBudgetChanges) {
        return false
      } else {
        return this.forceOpen || this.budgetChange?.budgetAdjustments?.length > 1 // if there is one result don't even bother showing it
      }
    },
    accountPlanId () {
      return this.account?.accountPlanId
    },
    tableItems () {
      return this.budgetChange.budgetAdjustments || this.originalBudgetChange.budgetAdjustments
    },
    tableClasses () {
      return this.inAccountView ? '' : 'scroll-table d-flex flex-column'
    }
  },
  methods: {
    isNil (val) {
      return _isNil(val)
    },
    syncUp () {
      this.updateAccount({ approvedAccountBudget: this.updatedBudgetAmountSum })
    },
    updateAccount (payload, dirty = true) {
      this.account = {
        ...this.account,
        dirty,
        ...payload
      }
    },
    parseHtmlMessage (msg) {
      let message = '<p>' + msg
      message = message.replace(/\n/g, '</p><p>') + '</p>'
      message = message.replace(/\s\s/g, '&nbsp;&nbsp;')
      return message
    },
    async previewBudgetChange (accountBudget, opts = {}) {
      const emitChange = opts?.emit
      const onFocus = opts?.focus

      if (onFocus && !this.hideBudgetChanges && this.hasBudgetChanges) {
        return false
      }

      this.previewLoading = true

      if (this.account.approvedAccountBudget !== accountBudget) {
        this.updateAccount({ approvedAccountBudget: accountBudget })
      }

      // add adjusted budget if it exists
      if (this.account.accountBudgetAdjustment) {
        accountBudget += this.account.accountBudgetAdjustment
      }

      const response = await this.$res.fetch.previewBudgetChange({
        accountPlanId: this.account.accountPlanId,
        approvedAccountBudget: accountBudget
      })

      if (response) {
        if (response?.budgetAdjustments?.length > 0) {
          this.budgetChange = response
          this.updateAccount({ manualBudgetAdjustments: _cloneDeep(response.budgetAdjustments) }, true)
          this.htmlMessage = this.parseHtmlMessage(response.message)
        } else {
          this.updateAccount({ manualBudgetAdjustments: undefined }, false)
          this.budgetChange = {}
          this.forceOpen = true
        }

        if (response?.defaultToOriginalAmounts === true) {
          /*
           * this is a customer level setting that will be returned on the API and the desired outcome is to keep the
           * original breakdown of values when changing the overall budget amount.
           */
          this.revertToOriginalAmounts()
        }
      }

      this.previewLoading = false

      if (emitChange) {
        this.$emit('input', this.account)
      }
    },
    toggleEditingMode () {
      this.forceOpen = !this.forceOpen
    },
    updateManualBudget (event, row) {
      if (!this.budgetChange.budgetAdjustments) {
        this.budgetChange.budgetAdjustments = [...this.originalBudgetChange.budgetAdjustments]
      }
      const index = this.budgetChange.budgetAdjustments.findIndex(adj => adj.budgetPlanId === row.item.budgetPlanId)
      this.budgetChange.budgetAdjustments[index].updatedBudgetAmount = event
      this.updateAccount({ manualBudgetAdjustments: _cloneDeep(this.budgetChange.budgetAdjustments) })
    },
    revertToOriginalAmounts () {
      if (this.budgetChange?.budgetAdjustments) {
        const revertedBudgets = this.budgetChange.budgetAdjustments.map(adj => ({
          ...adj,
          updatedBudgetAmount: adj.budgetAmount
        }))
        this.budgetChange.budgetAdjustments = revertedBudgets

        this.updateAccount({ manualBudgetAdjustments: _cloneDeep(this.budgetChange.budgetAdjustments) })
      }
    },
    async clearOverrides () {
      const response = await this.$res.fetch.previewBudgetChange({
        accountPlanId: this.account.accountPlanId,
        approvedAccountBudget: this.account.approvedAccountBudget
      })
      if (response) {
        if (!this?.budgetChange?.budgetAdjustments) {
          // initialize
          this.budgetChange.budgetAdjustments = response.budgetAdjustments
        } else {
          // update
          const budgetAdjustments = this.budgetChange.budgetAdjustments.map((adj, i) => ({
            ...adj,
            updatedBudgetAmount: response.budgetAdjustments[i]?.updatedBudgetAmount ?? null
          }))
          this.budgetChange.budgetAdjustments = budgetAdjustments
        }

        this.updateAccount({ manualBudgetAdjustments: _cloneDeep(this.budgetChange.budgetAdjustments) })
      }
    },
    async clearPending () {
      await this.$res.set.clearPendingAccount({ accountPlanId: this.account.accountPlanId })

      this.updateAccount({
        nextApprovedAccountBudget: null
      })
    }
  },
  mounted () {
    if (this.inNotifications) {
      this.previewBudgetChange(this.account.approvedAccountBudget, { emitChange: true })
    }
  }
}
</script>

<style scoped lang="scss">
.b-right-content {
  opacity: 0;
  transition: opacity 400ms ease-in 350ms;
}

.active-budget-editing {
  background-color: #f9f9f9;
  padding-left: 0.5em;
  padding-right: 0.5em;

  &.b-left {
    z-index: 2;
    position: relative;
  }

  &.b-right {
    .b-right-content {
      opacity: 1;
    }
  }
}
</style>
<style lang="scss">
.account-view-budget {
  .name {
    width: 200px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .scroll-table tbody {
    display: block;
    max-height: calc(100vh - 530px);
    overflow: auto;
  }
}
</style>
